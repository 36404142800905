import { Provider } from "react-redux";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import DayPlannerPage from "./pages/DayPlannerPage/DayPlannerPage";
import ReturnGoodsPage from "./pages/ReturnGoodsPage/ReturnGoodsPage";
import ReturnReasons from "./pages/ReturnReasons/ReturnReasons";
import LinkRequest from "./pages/LinkRequest/LinkRequest";
import TrackingPage from "./pages/TrackingPage/TrackingPage";
import ShipmentsListPage from "./pages/ShipmentsListPage/ShipmentsListPage";
import FindShipments from "./pages/FindShipments/FindShipments";
import PudoPage from "./pages/PudoPage/PudoPage";
import RatingPage from "./pages/RatingPage/RatingPage";
import Login from "./pages/Login/Login";
import rootReducer from "./reducers/index.js";
import "./styles/General.scss";
import MyHelmet from "./utils/helmets";

// CAMBIO STILE IN BASE A NODE ENV
require(`./assets/${process.env.REACT_APP_TARGET}/index.css`);

export const store = createStore(rootReducer, applyMiddleware(thunk));

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <MyHelmet />
        <Switch>
          <Route exact path="/" component={TrackingPage} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/reso" component={ReturnGoodsPage} />
          <Route exact path="/booking" component={DayPlannerPage} />
          <Route exact path="/return" component={ReturnReasons} />
          <Route exact path="/richiesta-link" component={LinkRequest} />
          <Route exact path="/shipments-list" component={ShipmentsListPage} />
          <Route exact path="/find-shipments" component={FindShipments} />
          <Route exact path="/select-pudo" component={PudoPage} />
          <Route exact path="/rate-us" component={RatingPage} />
          {/* <Route path="/pagina-non-trovata" component={Error} /> */}
          <Redirect to="/pagina-non-trovata" />
        </Switch>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
