import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { translate } from "../assets/lemmata";
import Favicon from "../assets/Favicon";

function format() {
  const lan = localStorage.getItem("lan") || "it-it";
  let address = window.location.pathname.split("/");
  const translatedAddress = translate(address[1], lan);
  if (address[1] !== "") {
    return translatedAddress;
  } else {
    return "";
  }
}

const helmets = {
  typ: "Typ",
  a2u: "A2U",
  a2ufr: "A2U",
};

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

function MyHelmet() {
  const [path, setPath] = useState(format());
  const [authenticating, setAuthenticating] = useState(true);
  const { pathname } = useLocation();
  const authKey = useQuery().get("key") || localStorage.getItem("key");

  const shipping = useSelector((state) => state.shippings.data);

  useEffect(() => {
    setAuthenticating(authKey);
  }, [authKey]);

  useEffect(() => {
    setPath(format());
  }, [pathname]);

  const helmet = helmets[process.env.REACT_APP_TARGET];

  const hasChatWidget =
    process.env.REACT_APP_TARGET === "typ" &&
    !!authKey &&
    !!shipping &&
    !["BC", "SC"].some((serviceCode) => serviceCode === shipping?.serviceCode);

  return (
    <Helmet>
      <title>
        {helmet} {path && "-"} {path}
      </title>
      {authenticating && hasChatWidget && (
        // Start of typsrl Zendesk Widget script
        <script
          id="ze-snippet"
          src="https://static.zdassets.com/ekr/snippet.js?key=72b833a0-1a2e-4689-b949-9d6fca4eba31"
        ></script>
        // End of typsrl Zendesk Widget script
      )}
      {/* {authenticating && hasChatWidget && (
        <script>
          {`
            window.HaloOBA = {
              "config":{
                 "type":"widget",
                 "workspace":"2a3d0305-7fd4-4b5c-a7a0-40e0cc618954",
                 "flow":"2d833207-7148-4eb5-942d-6c0c5856f201",
                 "mode":"LIVE",
                 "enterprise": "25a7de79-5848-45fd-8733-a3df613588fc"
              },
            };
          `}
        </script>
      )}
      {authenticating && hasChatWidget && (
        <script
          src="https://oh.app.lifedata.ai/widget.js"
          async="true"
          defer="true"
          type="module"
        ></script>
      )} */}
      <link rel="icon" type="image/png" href={Favicon} sizes="32x32" />
    </Helmet>
  );
}

export default MyHelmet;
